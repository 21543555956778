<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between"
        >
          <h4 class="mb-0 font-size-18">Lista de Avaliações</h4>
        </div>
      </div>
    </div>
    <div class="row text-left">
      <div class="col-12">
        <div class="card">
          <div
            class="card-header bg-white border-bottom d-flex align-items-center justify-content-between"
          >
            <h5 class="card-title">
              <i class="fas fa-filter text-dark mr-2"></i>
              Filtro
            </h5>
            <b-button
              variant="light"
              @click.prevent="limparFiltro()"
              v-b-tooltip.hover
              title="Limpar Filtro"
            >
              <i class="bx bx-sync font-size-16 align-middle"></i>
            </b-button>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
                <label>Produto</label>
                <multiselect
                  v-model="product"
                  :options="products"
                  class="helo"
                  placeholder="Produto"
                  label="name"
                  select-label="Selecionar"
                  deselect-label="Remover"
                  :loading="isLoadingProduct"
                  @search-change="searchProduct"
                  :allow-empty="false"
                ></multiselect>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
                <label>Cliente</label>
                <multiselect
                  v-model="customer"
                  :options="customers"
                  class="helo"
                  placeholder="Cliente"
                  label="name"
                  select-label="Selecionar"
                  deselect-label="Remover"
                  :loading="isLoadingCustomer"
                  @search-change="searchCustomer"
                  :allow-empty="false"
                ></multiselect>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4">
                <label>Data</label>
                <b-form-datepicker
                  placeholder="Data de Avaliação"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  v-model="filtro.created_at"
                ></b-form-datepicker>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4">
                <label>Status</label>
                <multiselect
                  v-model="status"
                  :options="options_status"
                  class="helo"
                  placeholder="Status"
                  label="text"
                  select-label="Selecionar"
                  deselect-label="Remover"
                  :allow-empty="false"
                ></multiselect>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4">
                <label>Avaliação</label>
                <multiselect
                  v-model="filtro.rating"
                  :options="options_ratings"
                  class="helo"
                  placeholder="Avaliação"
                  select-label="Selecionar"
                  deselect-label="Remover"
                  :allow-empty="false"
                ></multiselect>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card" v-if="loading">
      <div class="card-body">
        <div class="text-center text-info my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </div>
    </div>
    <div class="card" v-else-if="error">
      <div class="card-body">
        <div class="text-center text-danger my-2">
          <h5 class="mb-3">Erro ao carregar dados!</h5>
          <img width="250" src="@/assets/images/error-img.png" />
        </div>
      </div>
    </div>
    <div class="row" v-else-if="ratings.length <= 0">
      <div class="col-12">
        <div class="card">
          <div class="card-body py-5">
            <h5 class="mb-3">Nenhum item encontrado!</h5>
            <img width="250" src="@/assets/images/not-data.svg" />
          </div>
        </div>
      </div>
    </div>
    <div class="row text-left" v-else>
      <div v-for="r in ratings" :key="r.id" class="col-xl-4 col-sm-6">
        <div class="card">
          <div class="card-body">
            <div class="media">
              <div class="avatar-md mr-4">
                <span v-for="img in r.product.image" :key="img.id">
                  <img
                    class="avatar-title rounded bg-light text-danger"
                    :src="img.image300"
                    alt
                    height="60"
                  />
                </span>
              </div>

              <div class="media-body overflow-hidden">
                <h5 class="text-truncate font-size-15">
                  <a href="#" class="text-dark">{{ r.product.name }}</a>
                </h5>
                <p class="text-muted mb-2">
                  {{ r.description.substr(0, 50) }}...
                </p>
                <p class="text-muted mb-2">
                  <i
                    class="fas fa-star text-warning mr-1"
                    v-for="i in r.rating"
                    :key="i + Math.random(50000)"
                  ></i>
                  <i
                    class="fas fa-star text-muted mr-1"
                    v-for="i in (5 - r.rating)"
                    :key="i + Math.random(50000)"
                  ></i>
                </p>
                <div class="team">
                  <a
                    href="#"
                    @click.prevent="
                      $router.push({
                        name: 'cliente',
                        params: { id: r.customer.id },
                      })
                    "
                    class="team-member d-inline-block text-muted"
                  >
                    <img
                      v-if="
                        r.customer.avatar &&
                        r.customer.avatar != null &&
                        r.customer.avatar != ''
                      "
                      :src="r.customer.avatar"
                      class="rounded-circle avatar-xs m-1"
                      :alt="r.customer.name"
                    />
                    <img
                      v-else
                      src="@/assets/images/notimg.jpg"
                      class="rounded-circle avatar-xs m-1"
                      :alt="r.customer.name"
                    />
                    {{ r.customer.name }}
                  </a>
                </div>
                <ul class="list-inline mb-0 mt-2">
                  <li class="list-inline-item mr-3">
                    <span
                      class="badge"
                      :class="{
                        'badge-success': `${r.status}` == 1,
                        'badge-info': `${r.status}` == 0,
                        'badge-danger': `${r.status}` == 2,
                      }"
                    >
                      {{
                        r.status == 1
                          ? 'Aprovado'
                          : r.status == 0
                          ? 'Pendente'
                          : 'Recusado'
                      }}
                    </span>
                  </li>
                  <li class="list-inline-item mr-3">
                    <i class="bx bx-calendar mr-1"></i>
                    {{ r.created_at | moment('DD/MM/YYYY HH:mm') }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="px-4 py-3 border-top text-center">
            <button
              class="btn btn-success"
              v-b-tooltip.hover.top
              title="Visualizar"
              @click.prevent="modelShow(r)"
            >
              <i class="fas fa-eye text-white"></i>
            </button>
            <button
              v-if="r.status != 1"
              class="btn btn-info ml-2"
              @click.prevent="ac(r, 1)"
              v-b-tooltip.hover.top
              title="Aprovar"
            >
              <i class="fas fa-thumbs-up"></i>
            </button>
            <button
              v-if="r.status != 2"
              class="btn btn-danger ml-2"
              @click.prevent="ac(r, 2)"
              v-b-tooltip.hover.top
              title="Recusar"
            >
              <i class="fas fa-thumbs-down"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="showModal" title="Avaliação" centered>
      <div class="media" v-if="rating != null">
        <div class="avatar-md mr-4" v-if="rating.product.image != null">
          <span v-for="img in rating.product.image" :key="img.id">
            <img
              class="avatar-title rounded bg-light text-danger"
              :src="img.image300"
              alt
              height="60"
            />
          </span>
        </div>
        <div class="avatar-md mr-4" v-else>
          <img
            class="avatar-title rounded bg-light text-danger"
            src="@/assets/images/notpro.jpg"
            alt
            height="60"
          />
        </div>

        <div class="media-body overflow-hidden">
          <h5 class="text-truncate font-size-15">
            <a href="#" class="text-dark">{{ rating.product.name }}</a>
          </h5>
          <p class="text-muted mb-2">{{ rating.description }}</p>
          <p class="text-muted mb-2">
            <i
              class="fas fa-star text-warning mr-1"
              v-for="i in rating.rating"
              :key="i + Math.random(50000)"
            ></i>
            <i
              class="fas fa-star text-muted mr-1"
              v-for="i in (5 - rating.rating)"
              :key="i + Math.random(50000)"
            ></i>
          </p>
          <div class="team">
            <a
              href="javascript: void(0);"
              class="team-member d-inline-block text-muted"
            >
              <img
                v-if="
                  rating.customer.avatar &&
                  rating.customer.avatar != null &&
                  rating.customer.avatar != ''
                "
                :src="`${rating.customer.avatar}`"
                class="rounded-circle avatar-xs m-1"
                alt
              />
              <img
                v-else
                src="@/assets/images/notimg.jpg"
                class="rounded-circle avatar-xs m-1"
                alt
              />

              {{ rating.customer.name }}
            </a>
          </div>
          <ul class="list-inline mb-0 mt-2">
            <li class="list-inline-item mr-3">
              <span
                class="badge"
                :class="{
                  'badge-success': `${rating.status}` == 1,
                  'badge-info': `${rating.status}` == 0,
                  'badge-danger': `${rating.status}` == 2,
                }"
              >
                {{
                  rating.status == 1
                    ? 'Aprovado'
                    : rating.status == 0
                    ? 'Pendente'
                    : 'Recusado'
                }}
              </span>
            </li>
            <li class="list-inline-item mr-3">
              <i class="bx bx-calendar mr-1"></i>
              {{ rating.created_at | moment('DD/MM/YYYY HH:mm') }}
            </li>
          </ul>
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button
          v-if="rating.status != 1"
          variant="success"
          @click.prevent="ac(rating, 1)"
        >
          Aprovar
        </b-button>
        <b-button
          v-if="rating.status != 2"
          variant="danger"
          @click.prevent="ac(rating, 2)"
        >
          Recusar
        </b-button>
        <b-button variant="secondary" @click="showModal = false">
          Fechar
        </b-button>
      </template>
    </b-modal>
    <div class="row pb-5" v-if="loadingbtn">
      <div class="col-12 text-center">
        <b-button variant="info" @click.prevent="carregarMais()">
          <i class="bx bx-plus font-size-16 align-middle mr-2"></i>
          Carregar Mais
        </b-button>
      </div>
    </div>
    <infinite-loading @infinite="infiniteHandler">
      <div slot="spinner" class="text-info"></div>
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'
import Multiselect from 'vue-multiselect'
export default {
  components: {
    InfiniteLoading,
    Multiselect,
  },
  data() {
    return {
      showModal: false,
      ratings: [],
      rating: null,
      filtro: {
        limit: 10,
        page: 1,
      },
      status: null,
      options_status: [
        { value: 0, text: 'Pendente' },
        { value: 1, text: 'Aprovado' },
        { value: 2, text: 'Recusado' },
      ],
      options_ratings: [1, 2, 3, 4, 5],
      filtroProduct: {
        limit: 10,
      },
      products: [],
      isLoadingProduct: true,
      product: null,
      filtroCustomer: {
        limit: 10,
      },
      customers: [],
      isLoadingCustomer: true,
      customer: null,
      loading: true,
      error: false,
      loadingbtn: true,
    }
  },
  watch: {
    status: function () {
      if (this.status != null) {
        this.filtro.status = this.status.value
        this.listRatings()
      }
    },
    'filtro.rating': function () {
      this.listRatings()
    },
    'filtro.created_at': function () {
      this.listRatings()
    },
    product: function () {
      if (this.product != null) {
        this.filtro.product_id = this.product.id
        this.listRatings()
      }
    },
    customer: function () {
      if (this.customer != null) {
        this.filtro.customer_id = this.customer.id
        this.listRatings()
      }
    },
  },
  mounted() {
    this.listProducts()
    this.listCustomers()
  },
  methods: {
    ...mapActions('rating', ['ActionRatingAll', 'ActionRatingUpdate']),
    ...mapActions('product', ['ActionProductAll']),
    ...mapActions('customer', ['ActionCustomerAll']),
    async carregarMais() {
      try {
        this.loadingbtn = true
        await this.ActionRatingAll(this.filtro).then((res) => {
          if (res.data.data.list.length) {
            this.filtro.page += 1
            this.ratings.push(...res.data.data.list)
          } else {
            this.loadingbtn = false
          }
          this.loading = false
          this.error = false
        })
      } catch (_) {
        this.ratings = []
        this.loading = false
        this.error = true
      }
    },
    async infiniteHandler($state) {
      try {
        this.loadingbtn = true
        await this.ActionRatingAll(this.filtro).then((res) => {
          if (res.data.data.list.length) {
            this.filtro.page += 1
            this.ratings.push(...res.data.data.list)
            $state.loaded()
          } else {
            this.loadingbtn = false
            $state.complete()
          }
          this.loading = false
          this.error = false
        })
      } catch (_) {
        this.ratings = []
        $state.complete()
      }
    },
    async listRatings() {
      try {
        this.filtro.page = 1
        this.loading = true
        this.loadingbtn = true
        await this.ActionRatingAll(this.filtro).then((res) => {
          if (res.data.data.list.length) {
            this.filtro.page = 2
            this.ratings = res.data.data.list
          } else {
            this.loadingbtn = false
            this.ratings = []
          }
          this.loading = false
          this.error = false
        })
      } catch (_) {
        this.ratings = []
        this.loading = false
        this.error = true
      }
    },
    searchProduct(e) {
      this.filtroProduct.search = e
      this.listProducts()
    },
    async listProducts() {
      try {
        this.isLoadingProduct = true
        await this.ActionProductAll(this.filtroProduct).then((res) => {
          this.products = res.data.data.list
          this.isLoadingProduct = false
        })
      } catch (_) {
        this.products = []
        this.isLoadingProduct = false
      }
    },
    searchCustomer(e) {
      this.filtroCustomer.search = e
      this.listCustomers()
    },
    async listCustomers() {
      try {
        this.isLoadingCustomer = true
        await this.ActionCustomerAll(this.filtroCustomer).then((res) => {
          this.customers = res.data.data.list
          this.isLoadingCustomer = false
        })
      } catch (_) {
        this.customers = []
        this.isLoadingCustomer = false
      }
    },
    modelShow(data) {
      this.rating = data
      this.showModal = !this.showModal
    },
    async ac(data, ac) {
      try {
        this.$swal({
          title: 'Aguarde!',
          text: 'Salvando alterações',
          icon: 'info',
          showConfirmButton: false,
        })
        data.status = ac
        var dados = {
          data: data,
          id: data.id,
        }
        await this.ActionRatingUpdate(dados)
        this.$swal({
          title: 'Sucesso',
          text: 'Ação realizada com sucesso!',
          icon: 'success',
          timer: 2000,
          timerProgressBar: true,
        })
        this.showModal = false
      } catch (e) {
        this.$swal({
          title: 'Erro!',
          text: e.body.message,
          icon: 'error',
          timer: 2000,
          timerProgressBar: true,
        })
      }
    },
    limparFiltro() {
      ;(this.filtro = {
        limit: 10,
        page: 1,
      }),
        (this.status = null)
      this.product = null
      this.customer = null
      this.listRatings()
    },
  },
}
</script>
